.engadin-v1 {

	/* margin-top: var(--page-margin-top); */
	@media (max-width: 991px) {
		margin-top: var(--page-margin-top);
	}

	img {
		max-width: 100%;
	}

	.banner-slide {

		.owl-nav,
		.owl-dots {
			display: none !important;
		}

	}


	.gallery-in-four {
		display: flex;
		flex-wrap: wrap;
		margin-left: -5px;
		margin-right: -5px;

		.gallery1 {
			width: 25%;
			padding: 0 5px;
			margin: 0 0 10px 0;

			@media (max-width: 767px) {
				width: 50%;
			}

			img {
				height: 104px;
				object-fit: cover;
				width: 100%;

				@media (max-width: 767px) {
					height: 130px;
				}

				@media all and (min-width: 768px) and (max-width: 991px) {
					height: 50px;
				}

				@media all and (min-width: 992px) and (max-width: 1199px) {
					height: 70px;
				}

				@media all and (min-width: 1200px) and (max-width: 1350px) {
					height: 85px;
				}
			}
		}
	}
}