.about-us-v1 {
	margin-top: 140px;

	/*	.inner-banner {
		background-image: url(RESOURCE/img/bg-wir.jpg);
	}  */

	@media (max-width: 768px) {
		margin-top: 180px;
	}

	.team-members {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(min(var(--about-us-grid-width), 100%), 1fr));
		grid-gap: 1em;
	}

	.member-info {
		text-align: center;
		background-color: var(--color-white);

		.name {
			font-weight: 600;
		}

		.resort {
			font-size: var(--font-size-md);
			color: var(--color-grey-dark);
			margin-bottom: 10px;
		}

		.phone {
			font-size: var(--font-size-md);
		}

		.email {
			font-size: var(--font-size-md);
		}

	}

	.list {
		li {
			position: relative;
			padding: 0 0 0 25px;
			margin: 0 0 10px 0;

			&:before {
				display: block;
				content: "\f00c";
				font-family: Font Awesome\ 5 Free;
				font-weight: 600;
				position: absolute;
				left: 0;
				top: 5px;
				font-size: 14px;
			}
		}
	}
}