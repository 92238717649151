.unit-title-v1 {
	.stars {
		color: var(--color-stars);
		font-size: var(--font-size-md);
		vertical-align: top;

		@media (max-width: 992px) {
			margin: 10px 0 30px 0;
			display: block;
			width: 100%;
		}
	}
}