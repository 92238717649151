.unit-pricelist-v1 {
	.show-more {
		span {
			font-size: var(--font-size-md);
		}
	}

	.pricelist {
		margin: 30px 0 0 0;



		.mainrow {

			td {
				font-weight: 500;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}

		.subrow {

			td {
				vertical-align: bottom;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}
	}
}