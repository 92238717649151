.searchbar-v1 {
	position: absolute;
	bottom: 40px;
	width: 100%;

	@media (max-width: 767px) {
		top: unset;
		bottom: 50px;

	}

	@media (max-width: 576px) {
		padding: 0px 10px;
	}

	@media (max-height: 600px) {
		top: 375px;

	}

	.title {
		color: #fff;
		background-color: var(--color-secondary);
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		padding: 10px 20px;
		opacity: 0.8;

	}

	.search-form-area {
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		justify-content: space-between;
		background-color: #fff;
		background-color: var(--white);
		border-bottom: 1px solid #eaeaea;
		flex-flow: wrap;
		/*box-shadow: 0 2px 12px rgb(254 154 0 / 25%);*/



		.form-flex {
			width: 45%;
			padding: 20px 22px;
			border-right: 1px solid #f4f4f4;

			@media all and (min-width: 993px) and (max-width: 1199px) {
				padding: 20px 10px;
			}

			&.form-flex-checkbox {
				width: 20%;

				@media (max-width: 992px) {
					width: 100%;
				}

				@media all and (min-width: 993px) and (max-width: 1199px) {
					padding: 20px 5px 20px 20px;
				}

				label {
					margin: 0 !important;
				}

				.checkbox label:before {
					top: 5px;
				}

				.checkbox label:after {
					top: 4px;
				}
			}

			&.v2 {
				width: 45%;
			}

			@media (max-width:992px) {
				width: 100%;
				border-bottom: 1px solid #f4f4f4;
				border-right: none;
				padding: 6px 12px;

				&.v2 {
					width: 100%;
				}
			}

			.fa {
				position: absolute;
				right: 0;
				top: 12px;
			}

			.form-control {
				padding: 5px 30px 5px 0;
				height: auto;
				min-height: 40px;
				background-color: transparent;
				text-align: left;
				z-index: 1;
				position: relative;
				cursor: pointer;
				border: none;
				line-height: 20px;

			}

			>label {

				color: var(--black);
				font-size: 13px;
				text-transform: uppercase;
				display: block;
				font-weight: normal;
				letter-spacing: 1.5px;

				@media (max-width:992px) {
					display: none;
				}


			}

			.form-flex-area {
				position: relative;
			}
		}

		.datepicker-trigger {
			.asd__wrapper {
				@media (max-width: 767px) {
					margin: 0 auto;
				}
			}
		}

		.form-btn {
			width: 10%;
			display: flow-root;
			webkit-box-align: center;
			align-items: center;
			padding-right: 10px;

			@media (max-width:992px) {
				width: 100%;
				padding: 5px;
			}

			.btn {
				width: 101%;
				height: 65px;
				align-items: center;
				display: flex;
				justify-content: center;
				font-size: 28px;

				@media (max-width:992px) {
					height: 40px;
					font-size: 20px;
					width: 100%;
				}
			}
		}
	}

	.form-control:disabled,
	.form-control[readonly] {
		background-color: var(--color-white);
	}

	.list-group-item {
		color: var(--font-color-main);
		text-decoration: none;

		&:hover {
			color: var(--font-color-main);
			text-decoration: none;
		}

		&.active {
			background-color: transparent;
			border: 1px solid var(--color-primary);
		}
	}
}